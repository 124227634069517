import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import TitleHeader from '../components/TitleHeader';

// import Layout from '../components/layout';
// import SEO from "../components/seo"

import stomEstet from '../images/uslugi/stomEstet.png';
import impla from '../images/uslugi/impla.png';
import komple from '../images/uslugi/komple.png';
import medEstet from '../images/uslugi/medEstet.png';
import SiteData from './SiteData'


const Service = (props) => {
	const {languages, currentLang} = SiteData(props);
	console.log("Service", props)
	console.log("Service", languages, currentLang)

	return (
			<div className="uslugi">
				<TitleHeader title="Usługi" />
				{/* <img style={{ width: '100%' }} src={stomEstet} /> */}
				<div className="content">
					<div className="choose-service">
						<Link className="border-a" to={`${currentLang}/uslugi/stomatologia-estetyczna`}>
							<img
								style={{
									height: '100%',
									position: 'absolute',
									zIndex: -1
								}}
								src={stomEstet}
								alt="Stomatologia estetyczna"
							/>
							{/* <p className="text-white">Stomatologia estetyczna</p> */}
							<p className="text-white"><FormattedMessage id="menu.services.aestheticDentistry"/></p>
						</Link>
						<Link className="border-a" to={`${currentLang}/uslugi/implantologia`}>
							<img
								style={{
									height: '100%',
									position: 'absolute',
									zIndex: -1
								}}
								src={impla}
								alt="Implantologia"
							/>
							{/* <p className="text-white">Implantologia</p> */}
							<p className="text-white"><FormattedMessage id="menu.services.implantology"/></p>
						</Link>
						<Link className="border-a" to={`${currentLang}/uslugi/kompleksowa-opieka`}>
							<img
								style={{
									// width: '100%',
									height: '100%',
									position: 'absolute',
									zIndex: -1
								}}
								alt="Kompleksowa opieka i radiologia stomatologiczna"
								src={komple}
							/>
							{/* <p className="text-white small-text">
								Kompleksowa opieka i<br />
								radiologia stomatologiczna
							</p> */}
							<p className="text-white small-text"><FormattedMessage id="menu.services.comprehensiveDentalCare"/></p>

							{/* <p className="text-white">Kompleksowa opieka i</p>
							<p className="text-white">radiologia stomatologiczna</p> */}
						</Link>
						<Link className="border-a" to={`${currentLang}/uslugi/medycyna-estetyczna`}>
							<img
								style={{
									// width: '100%',
									height: '100%',
									position: 'absolute',
									zIndex: -1
								}}
								alt="Medycyna estetyczna"
								src={medEstet}
							/>
							{/* <p className="text-white">Medycyna estetyczna</p> */}
							<p className="text-white"><FormattedMessage id="menu.services.aestheticMedicine"/></p>

						</Link>
					</div>
				</div>
			</div>
	);
};
export default Service;
