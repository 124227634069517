import React from 'react';
import Layout from '../layout/pl';
import Service from '../components/Service';

const Uslugi = (props) => {
	return (
		<Layout {...props}>
			<Service {...props.location}/>
		</Layout>
	);
};
export default Uslugi;
